import { createSelector } from "reselect";
import { initialState } from "./initialState";

/**
 * Direct selector to the app state domain
 */

const selectAppDomain = (state) => state.app || initialState;

/**
 * Generic
 */

const makeSelectAppIsLoading = () =>
  createSelector(selectAppDomain, (substate) => substate.isLoading);

const makeSelectAppIsPageReady = () =>
  createSelector(selectAppDomain, (substate) => substate.isPageReady);

const makeSelectAppFormComplete = () =>
  createSelector(selectAppDomain, (substate) => substate.formComplete);

const makeSelectAppFormValue = () =>
  createSelector(selectAppDomain, (substate) => substate.formsValue);

const makeSelectAppFormValid = () =>
  createSelector(selectAppDomain, (substate) => substate.formsValid);

const makeSelectAppUserToken = () =>
  createSelector(selectAppDomain, (substate) => substate.userToken);

const makeSelectAppPlanList = () =>
  createSelector(selectAppDomain, (substate) => substate.planList);

const makeSelectAppCheckoutJourney = () =>
  createSelector(selectAppDomain, (substate) => substate.checkoutJourney);

const makeSelectAppUserDetails = () =>
  createSelector(selectAppDomain, (substate) => substate.userDetails);

const makeSelectAppTransactionHistory = () =>
  createSelector(selectAppDomain, (substate) => substate.transactionHistory);

const makeSelectAppCurrentSubscription = () =>
  createSelector(selectAppDomain, (substate) => substate.currentSubscription);

const makeSelectAppCurrentUsage = () =>
  createSelector(selectAppDomain, (substate) => substate.currentUsage);

const makeSelectAppMyMeetings = () =>
  createSelector(selectAppDomain, (substate) => substate.myMeetings);

const makeSelectAppMyMeetingsTotalPages = () =>
  createSelector(selectAppDomain, (substate) => substate.totalPages);

const makeSelectAppMyMeeting = () =>
  createSelector(selectAppDomain, (substate) => substate.myMeeting);

const makeSelectAppSharedMeetings = () =>
  createSelector(selectAppDomain, (substate) => substate.sharedMeetings);

export {
  selectAppDomain,
  makeSelectAppIsLoading,
  makeSelectAppIsPageReady,
  makeSelectAppFormComplete,
  makeSelectAppFormValue,
  makeSelectAppFormValid,
  makeSelectAppUserToken,
  makeSelectAppPlanList,
  makeSelectAppMyMeetingsTotalPages,
  makeSelectAppCheckoutJourney,
  makeSelectAppUserDetails,
  makeSelectAppTransactionHistory,
  makeSelectAppCurrentSubscription,
  makeSelectAppCurrentUsage,
  makeSelectAppMyMeetings,
  makeSelectAppMyMeeting,
  makeSelectAppSharedMeetings,
};
