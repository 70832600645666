import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicyContent = () => (
  <>
    <h1>Privacy Statement</h1>
    <p>
      Suzami Tech Private Limited, its subsidiaries hereinafter referred as
      ‘STPL’, ‘we’, ‘us’ or ‘our’ is committed to respect your privacy and
      choices. The privacy statement highlights our privacy practices regarding
      Personal Information that we collect and process depending on your
      association with STPL and nature of processing activity in compliance to
      applicable data privacy regulations.
    </p>
    <h2>Website Privacy Statement:</h2>
    <p>
      This privacy statement highlights our privacy practices regarding Personal
      Information that we collect and process about you through this website,
      moss.simplibot.com (the “Site”), Moss.ai mobile application (the “App”)
      and also for those Personal Information that you provide us while
      participating in our events and campaigns.
    </p>
    <h3>I. Personal Information we collect and process and how we use it</h3>
    <p>
      We will collect and use the following Personal Information about you:
      Information you provide to us
    </p>
    <ul>
      <li>
        Registration Information. When you create an account on our Services,
        you will be asked to provide your name, email, and a password, you may
        voluntarily add a profile picture. For Pro or Business plans which are
        paid Services, our payment processing partner Razorpay may also collect
        your name, billing address, and payment information. Payment information
        is not shared with us and is maintained by Razorpay.
      </li>
      <li>
        App Information. When you use the Services, you may provide us with your
        audio recordings (“Audio Recordings”), automatic Moss.ai screenshots and
        any text, images or videos that you upload or provide to us in the
        context of the Services. Moss.ai may take automatic screenshots which
        are available meeting transcripts to add value to the meetings by
        extracting useful visual information. The automatic screenshots will
        only take place in virtual meetings.
      </li>

      <li>
        Communication Information. When you contact us, you provide us with your
        phone number, email, and any other information you choose to provide
        over such communication, including information about your query.
      </li>
    </ul>
    <h3>II. Information you provide us about others</h3>
    <ul>
      <li>
        If you choose to collaborate on a task with your co-workers or friends,
        or refer us to them, you provide us with the email address and contact
        information of your co-workers or friends.
      </li>

      <li>
        If you provide an Audio Recording, this may contain the Personal
        Information of third parties. Before you do so, please make sure you
        have the necessary permissions from your co-workers, friends or other
        third parties before sharing Personal Information or referring them to
        us.
      </li>
    </ul>
    <h3>
      III. Information we automatically collect or is generated about you when
      use the Services
    </h3>
    <ul>
      <li>
        Usage Information: When you use the Services, you generate information
        pertaining to your use, including timestamps, such as access, record,
        share, edit and delete events, app use information, screenshots/screen
        captures taken during the meeting, interactions with our team, and
        transaction records.
      </li>

      <li>
        Device Information: We assign a unique user identifier (“UUID”) to each
        mobile device that accesses the Services. When you use our Services, you
        provide information such as your IP address, UUIDs, device IDs, web
        beacons and other device information (such as carrier type, whether you
        access our Service from a desktop or mobile platform, device model,
        brand, web browser and operating system).
      </li>

      <li>
        Cookies: We use Cookies and other similar technologies (“Cookies”) to
        enhance your experience when using the Service. For more information
        about our Cookies policy, see HOW WE USE COOKIES below.
      </li>
    </ul>
    <h3>IV. Information received from third parties.</h3>
    <ul>
      <li>
        Information we receive from third party platforms: When you connect
        third party platforms, apps or providers (such as Google Calendar, iCal
        or other calendar programs, Google Contacts or Zoom) to our Services, or
        when you register through a third party account (such as Google or
        Microsoft), we receive Personal Information that includes your username,
        profile picture, email address, time, location, calendar information,
        contact information from such third parties and any information you
        choose to upload to such third party platforms (“Platform Information”).
      </li>

      <li>
        Information from platforms our Services relies on: We receive
        transaction information from our payment processor Razorpay.
      </li>

      <li>
        Other third parties. We may receive additional information about you,
        such as demographic or interest attributes from third parties such as
        data or marketing partners and combine it with other information we have
        about you.
      </li>
    </ul>
    <p>
      We also collect, and use aggregated data such as statistical or
      demographic data for our purposes. Aggregated data may be derived from
      your Personal Information but is not Personal Information as this data
      will not directly or indirectly reveal your identity. However, if we
      combine or connect aggregated data with your Personal Information so that
      it can directly or indirectly identify you, we will treat the combined
      data as Personal Information which will be used in accordance with this
      Policy.
    </p>
    <h2> How we use your personal information</h2>
    <p>We use your Personal Information to:</p>
    <ul>
      <li>
        Set up your account. We use your registration information, device
        information and information received from third parties (such as your
        username, email address) in order to set up an account for you to use
        our Services. We do so in accordance with our contractual and
        precontractual obligations to you in order to provide you with an
        account to use the Services.
      </li>
      <li>
        Provide you with the Services. We use your audio recordings, usage
        information and platform information in order to provide you with the
        Services. In addition, we use your communication information to
        facilitate support (e.g. retrieval of a forgotten password). We do so in
        accordance with our contractual obligations to you in order to provide
        you with the Services.
      </li>
      <li>
        Improve and monitor the Services. We use information we automatically
        collect or generate about you when you use the Services, as well as
        information about your device such as device manufacturer, model and
        operating system, and the amount of free space on your device, to
        analyse the use of and improve our Services. We train our proprietary
        artificial intelligence technology on de-identified audio recordings. We
        also train our technology on transcriptions to provide more accurate
        services, which may contain Personal Information. We obtain explicit
        permission (e.g. when you rate the transcript quality and check the box
        to give Moss.ai and its third-party service provider(s) permission to
        access the conversation for training and product improvement purposes)
        for manual review of specific audio recordings to further refine our
        model training data.
      </li>
      <li>
        Communicate with you. If you contact us, we will use your contact
        information to communicate with you and, if applicable, your usage
        information to support your use of the Services.
      </li>
      <li>
        Send you newsletters about product news or updates that may be of
        interest to you. We will send you emails with news or updates pertaining
        to our Services. When doing so, we process your email address, name and
        may process your usage information. Your consent can be withdrawn at any
        time by following the unsubscribe mechanism at the bottom of each
        communication.
      </li>
      <li>
        Prevent fraud, defend Moss.ai against legal claims or disputes, enforce
        our terms and to comply with our legal obligations. It is in our
        legitimate interest to protect our interests by (1) monitoring the use
        of the Services to detect fraud or any other user behaviour which
        prejudices the integrity of our Services, (2) taking steps to remedy
        aforementioned fraud and behaviour, (3) defending ourselves against
        legal claims or disputes, and (4) enforcing our terms and policies. When
        doing so, we will process the Personal Information relevant in such a
        case, including information you provide us, information we automatically
        collect about you, and information which is provided to us by third
        parties.
      </li>
    </ul>
    <h3> I. Legal basis of the processing</h3>
    <p>
      We process your Personal Information when it is necessary for the
      performance of a contract to which you are the party or in order to take
      steps at your request prior to entering into a contract. This applies in
      any case where we provide services to you pursuant to a contract, such as
      when you use our website or process your registration on the app. If you
      do not provide the Personal Information that we need in order to provide
      our services, we will not be able to provide our services to you. We
      process your Personal Information for the performance of a contract in the
      following circumstances:
    </p>
    <ul>
      <li>
        to provide better usability, troubleshooting and app maintenance; and
      </li>
      <li>to create your user ID.</li>
    </ul>
    <p>
      We process your Personal Information when it is necessary for the purposes
      of a legitimate interest pursued by us or a third party (when these
      interests are not overridden by your data protection rights). This applies
      in the following circumstances:
    </p>
    <ul>
      <li>to identify you once you register on our app; and</li>
      <li>to contact you and respond to your questions or requests;</li>
      <li>
        to understand which parts of the app are visited and how frequently; and
      </li>
      <li>to provide access to desirable content based on your preferences.</li>
    </ul>
    <p>
      We process your Personal Information with your consent for the below
      purpose(s). Where we process Personal Data based on consent, your consent
      is revocable at any time.
    </p>
    <ul>
      <li>
        To know your interest of participation in ideathons or other
        competitions
      </li>
      <li>
        To contact you in furtherance to your participation in the ideathons or
        other competitions
      </li>
      <li>
        As a participant of the competition, to publish your name and
        corresponding details to make them available to larger audience on the
        Internet
      </li>
    </ul>
    <h3>II. Consequences of not providing Personal Information</h3>
    <p>
      If you choose not to provide your Personal Information that is mandatory
      to process your request, we may not be able to provide the corresponding
      service.
    </p>
    <h3>
      III. Data recipients, transfer, and disclosure of Personal Information:
    </h3>
    <p>
      STPL does not share your Personal Information with third parties for their
      direct marketing purposes.
    </p>
    <p>We share your Personal Information within</p>
    <ul>
      <li>STPL or with any of its subsidiaries;</li>
      <li>Business partners;</li>
      <li>Service vendors;</li>
      <li>Authorised third-party agents; or</li>
      <li>Contractors.</li>
    </ul>
    <p>STPL does not sell your Personal Information to third parties.</p>
    <h3>IV. Use of cookies:</h3>
    <p>Please refer to the Cookie Policy.</p>

    <h3>V. Do Not Track</h3>
    <p>
      Do Not Track (DNT) is a privacy preference that users can set in some
      platforms or devices, allowing users to opt out of tracking by websites
      and online services. At the present time, the World Wide Web Consortium
      (W3C) has not yet established universal standards for recognizable DNT
      signals and therefore, STPL and the App do not recognize DNT.
    </p>

    <h3>VI. Your Rights:</h3>
    <p>
      Subject to the laws of your country, You have the right to access,
      correct, delete or transfer your Personal Information that we hold,
      including your profile and preferences. You also have the right to object
      to certain processing and, where we have asked for your consent to process
      your Personal Information, to withdraw this consent. Where we process your
      Personal Information because we have a legitimate interest in doing so (as
      explained above), you also have a right to object to this. These rights
      may be limited in some situations – for example, where we can demonstrate
      that we have a legal requirement to process your Personal Information.
    </p>
    <p>
      If you are a Brazilian user, you have additional rights to take
      confirmation on the existence of your personal information, portability of
      your personal information to another service provider if applicable,
      anonymization or blocking of unnecessary information, information about
      public and private entities with which we have shared your data.
    </p>
    <p>
      You can assert your rights in the corresponding sections where such
      information was provided or by contacting us at{" "}
      <Link to="/#">connect@simplibot.com (Attn: Privacy Officer)</Link>
    </p>
    <h3>VII. Data security</h3>
    <p>
      STPL adopts reasonable and appropriate security practices and procedures
      including administrative, physical security, and technical controls in
      order to safeguard your Personal Information.
    </p>

    <h3>VIII. Data retention</h3>
    <p>
      Personal Information will not be retained for a period more than necessary
      to fulfil the purposes outlined in this privacy statement, unless a longer
      retention period is required by law or for directly related legitimate
      business purposes.
    </p>

    <h3>IX. Contact your Local Data Protection Authority</h3>
    <p>
      Depending on the laws of the countries where you reside, if you are
      unhappy with how we safeguard your personal data, you have the right to
      bring a complaint to your local data protection authority.
    </p>

    <h3>X. Linked websites</h3>
    <p>
      Our privacy practices regarding Personal Information that we collect and
      store about you through our portals such as Simplibot and other products
      will be as per the privacy policy of those portals.
    </p>
    <p>
      STPL provides links to third-party websites and services. However, STPL is
      not responsible for the privacy statements, practices, or the contents of
      such third-party websites.
    </p>

    <h3>XI. Children’s Privacy</h3>
    <p>
      The website and app does not knowingly solicit or collect personally
      identifiable information online from children under the age of 13 without
      prior verifiable parental consent. If STPL learns that a child under the
      age of 13 has submitted personally identifiable information online without
      parental consent, it will not take measures to delete such information
      from its databases and to not use such information for any purpose (except
      where necessary to protect the safety of the child or others as required
      or allowed by law). If you become aware of any personally identifiable
      information we have collected from children under 13, please call us at
      +91 80 4654 0779 or email us at connect@simplibot.com (Attn: Privacy
      Officer).
    </p>

    <p>
      Minors under 18 years of age may have the personal information that they
      provide to us deleted by sending an email to connect@simplibot.com (Attn:
      Privacy Officer) requesting deletion. Please note that, while we make
      reasonable efforts to comply with such requests, deletion of your personal
      information does not ensure complete and comprehensive removal of that
      data from all systems.
    </p>

    <h3>XII. How to contact us</h3>

    <p>
      If you have any questions regarding our privacy practices or this privacy
      statement, or to request this privacy statement in another format, please
      contact us at:
    </p>
    <p>
      Contact person: Himanshu Saxena Contact address: Tower 7, STPL, Prestige
      Ferns Residency, Haralur Main Road, HSR Layout, Bangalore 560 102, India
      Phone: +91 80 4654 0779 Email: connect@simplibot.com (Attn: Privacy
      Officer)
    </p>

    <h3>XIII. Updates to this privacy statement</h3>
    <p>
      STPL may change the data privacy practices and update this privacy
      statement as and when the need arises, and the same will be made available
      on the website. But our commitment to protect the privacy of website users
      will continue to remain.
    </p>
  </>
);

export default PrivacyPolicyContent;
