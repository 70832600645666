import React, { useEffect } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import {
  makeSelectAppFormValue,
  makeSelectAppFormValid,
  makeSelectAppFormComplete,
} from "../App/selectors";
import { inputChange, changePassword, resetFlow } from "../App/actions";

import FormComponent from "../../components/FormComponent";

import { formFields } from "./internalConfig";

const ChangePassword = ({
  dChangePassword,
  dInputChange,
  formValue,
  formValid,
  isPageReady,
  isLogin,
  formComplete,
  location,
  dResetFlow,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    if (isPageReady) {
      dResetFlow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageReady, location.pathname]);
  if (isPageReady && !isLogin) {
    return <Redirect to="/login" />;
  }
  if (isPageReady && formComplete.CHANGE_PASSWORD) {
    return <Redirect to="/profile" />;
  }

  return (
    <div className="container-fluid container-login100">
      <div className="wrap-login100">
        <div className="form-block">
          <h1>Change Password</h1>
          <FormComponent
            formFields={formFields}
            formValue={formValue.changePassword}
            formValid={formValid.changePassword}
            handleInputChange={dInputChange}
            submitLabel="Submit"
            handleSubmit={dChangePassword}
          />
        </div>
        <div className="link-block">
          <NavLink to="/login">
            <strong>Mood Changed?</strong>
            <span className="mossai-text"> Profile</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  formValue: makeSelectAppFormValue(),
  formValid: makeSelectAppFormValid(),
  formComplete: makeSelectAppFormComplete(),
});

function mapDispatchToProps(dispatch) {
  return {
    dResetFlow: () => {
      dispatch(resetFlow());
    },
    dInputChange: (value, fieldName) =>
      dispatch(inputChange("changePassword", fieldName, value)),
    dChangePassword: () => {
      dispatch(changePassword(formFields));
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ChangePassword);
