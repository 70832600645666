export const formFields = [
  {
    readOnly: false,
    label: "Email",
    name: "email",
    type: "email",
    placeholder: "Enter Registered Email",
    validations: [],

    fieldName: "email",
    validationRules: {},
  },
  {
    readOnly: false,
    label: "Password",
    name: "password",
    type: "password",
    placeholder: "Enter Password",
    validations: [],
    fieldName: "password",
    validationRules: {},
  },
];
