export const formFields = [
  {
    readOnly: false,
    label: "Current Password",
    name: "oldPassword",
    type: "password",
    placeholder: "Enter Current Password",
    validations: [{ name: "isRequired", message: " is Mandatory" }],
    fieldName: "oldPassword",
    validationRules: {
      isRequired: true,
    },
  },
  {
    readOnly: false,
    label: "New Password",
    name: "password",
    type: "password",
    placeholder: "Enter New Password",
    validations: [
      { name: "isRequired", message: " is Mandatory" },
      { name: "minChar", message: " Length is Minimum 6" },
    ],
    fieldName: "password",
    validationRules: {
      isRequired: true,
      minChar: 6,
    },
  },
  {
    readOnly: false,
    label: "Confirm Password",
    name: "confirmPassword",
    type: "password",
    placeholder: "Reneter New Password",
    validations: [
      { name: "isRequired", message: " is Mandatory" },
      {
        name: "matchField",
        message: " should match New Password",
      },
    ],
    fieldName: "confirmPassword",
    validationRules: {
      isRequired: true,
      matchField: "password",
    },
  },
];
