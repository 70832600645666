import React from "react";
import { NavHashLink } from "react-router-hash-link";
        const HowItWorks = () => (
        <section className="how-it-works pt-3">
    <div className="container" data-aos="fade-up">
        <div className="content row">
            <div className="col-md-6 col-lg-8 d-flex justify-content-between flex-column">
                <div className="box">
                    <h2>
                        Are you tired of drowning in paperwork? <br/>
                        <span className="mossai-text">Moss.ai</span> is here to lighten your load. 
                    </h2>
                    <br/>
                    <h3>
                        Our innovative mobile application is designed specifically for healthcare practitioners like you – doctors, clinicians, physicians, diabetologists, cardiologists, ENT specialists, and more. Say goodbye to tedious clerical work and hello to efficiency.<br/><br/>
                        Our advanced technology streamlines the process, allowing you to focus on what truly matters – providing exceptional care to your patients.
                    </h3>
                     <br/>
                </div>
                <div>
                    <NavHashLink className="btn btn-primary" to="/home#contact">
                        Request a quote
                    </NavHashLink>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 mt-3">
                <ul className="mb-0">
                    <li>
                        <i className="bi bi-check-circle"></i>Ambient Transcriptions
                    </li>
                    <li>
                        <i className="bi bi-check-circle"></i>Seamless Diarization
                    </li>
                    <li>
                        <i className="bi bi-check-circle"></i>Regional language support
                    </li>
                    <li>
                        <i className="bi bi-check-circle"></i>Get structured clinical notes draft
                    </li>
                    <li>
                        <i className="bi bi-check-circle"></i>Automated patient summary
                    </li>                    
                </ul>
            </div>
        </div>
    </div>    
</section>
        );
export default HowItWorks;
