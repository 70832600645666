import React, { useState } from "react";
import {
Modal,
        ModalHeader,
        ModalTitle,
        ModalBody,
        ModalFooter,
        Button,
        } from "react-bootstrap";
import { moneyFormatter } from "../../utils/common";

const Popup = ({ isOpen, onSubmit, plan, closeModal, moveToPage, userDetails, isLogin }) => {
    const [duration, setDuration] = useState("");
    const [planId, setPlanId] = useState("");
    return (
            <Modal size="md" show={isOpen}>
                <ModalHeader className="bg-success">
                    <ModalTitle className="text-white">{plan.name}</ModalTitle>
                </ModalHeader>
                <ModalHeader>
                    <strong className="font-italic text-warning">Select Duration</strong>
                </ModalHeader>
                <ModalBody>
                    <ul className="p-0">
                        <li className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="duration"
                                id="monthly"
                                onChange={e => {
                                                setDuration("MONTHLY");
                                                setPlanId((isLogin && (userDetails?.country != "IN" && plan.monthlyPrice_EUR)) ? plan.monthlyPriceId_EUR : plan.monthlyPriceId);
                                            }}
                                checked={duration === "MONTHLY"}
                                />
                            <label className="form-check-label" htmlFor="monthly">                            
                                Monthly ({moneyFormatter((isLogin && (userDetails?.country != "IN" && plan.monthlyPrice_EUR)) ? plan.monthlyPrice_EUR : plan.monthlyPrice)})
                            </label>
                        </li>
                        <li className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="duration"
                                id="quarterly"
                                onChange={e => {
                                        setDuration("QUARTERLY");
                                        setPlanId((isLogin && (userDetails?.country != "IN" && plan.quarterlyPrice_EUR)) ? plan.quarterlyPriceId_EUR : plan.quarterlyPriceId);
                                    }}
                                checked={duration === "QUARTERLY"}
                                />
                            <label className="form-check-label" htmlFor="quarterly">
                                Quarterly ({moneyFormatter((isLogin && (userDetails?.country != "IN" && plan.quarterlyPrice_EUR)) ? plan.quarterlyPrice_EUR : plan.quarterlyPrice)})
                            </label>
                        </li>
                        <li className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="duration"
                                id="yearly"
                                onChange={e => {
                                        setDuration("YEARLY");
                                        setPlanId((isLogin && (userDetails?.country != "IN" && plan.yearlyPrice_EUR)) ? plan.yearlyPriceId_EUR : plan.yearlyPriceId);
                                    }}
                                checked={duration === "YEARLY"}
                                />
                            <label className="form-check-label" htmlFor="yearly">
                                Yearly ({moneyFormatter((isLogin && (userDetails?.country != "IN" && plan.yearlyPrice_EUR)) ? plan.yearlyPrice_EUR : plan.yearlyPrice)})
                            </label>
                        </li>
                    </ul>
                </ModalBody>
                <ModalFooter className="pricing">
                    <Button
                        className="get-started-btn"
                        onClick={() => {
                                onSubmit({...plan, duration, planId});
                                moveToPage("/cart");
                                closeModal();
                            }}
                        >
                        Confirm
                    </Button>
                    <Button
                        className="get-started-btn-outline m-l-10"
                        onClick={() => closeModal()}
                        >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            );
};

export default Popup;
