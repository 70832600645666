import React, { useEffect } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import {
  makeSelectAppFormValue,
  makeSelectAppFormValid,
  makeSelectAppFormComplete,
} from "../App/selectors";
import { inputChange, resetPassword } from "../App/actions";

import FormComponent from "../../components/FormComponent";

import { formFields } from "./internalConfig";

const ResetPassword = ({
  isLogin,
  dResetPassword,
  dInputChange,
  formValue,
  formValid,
  formComplete,
  isPageReady,
  location,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);
  if (isPageReady && isLogin) {
    return <Redirect to="/profile" />;
  }
  if (isPageReady && formComplete.RESET_PASSWORD) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="container-fluid container-login100">
      <div className="wrap-login100">
        <div className="form-block">
          <h1>Reset Password</h1>
          <FormComponent
            formFields={formFields}
            formValue={formValue.resetPassword}
            formValid={formValid.resetPassword}
            handleInputChange={dInputChange}
            submitLabel="Submit"
            handleSubmit={dResetPassword}
          />
        </div>
        <div className="link-block">
          <NavLink to="/login">
            <strong>Remember Password?</strong>
            <span className="mossai-text"> Login</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  formValue: makeSelectAppFormValue(),
  formValid: makeSelectAppFormValid(),
  formComplete: makeSelectAppFormComplete(),
});

function mapDispatchToProps(dispatch) {
  return {
    dInputChange: (value, fieldName) =>
      dispatch(inputChange("resetPassword", fieldName, value)),
    dResetPassword: () => {
      dispatch(resetPassword(formFields));
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ResetPassword);
