import React, { useEffect } from "react";
import Faq from "react-faq-component";

const data = {
  title: "FAQ",
  rows: [
    {
      title: `How to transcribe audio to text free?`,
      content: `<span className="mossai-text">Moss.ai</span> gives one month free transcription services for anyone registering on the mobile application available on App Store and Play Store.`,
    },
    {
      title: `How to transcribe a YouTube video?`,
      content: `You can either upload an audio version of the YouTube video on <span className="mossai-text">Moss.ai</span> mobile application or you can  play the YouTube video and record it using <span className="mossai-text">Moss.ai</span>.`,
    },
    {
      title: `Where does live transcription save?`,
      content: `<span className="mossai-text">Moss.ai</span> does not create LIVE transcription, instead all processed audio files transcribed are stored and available for all user accounts.`,
    },
    {
      title: `What is auditory learning?`,
      content: `In auditory learning, individuals rely on their ability to listen and interpret sounds, tones, and words. <span className="mossai-text">Moss.ai</span> enables machine learning for all its users.`,
    },
    {
      title: `What types of audio files can Moss.ai transcribe?`,
      content: `<span className="mossai-text">Moss.ai</span> can transcribe all mp4 format files to be transcribed with the use of an upload function.`,
    },
    {
      title: `Can I edit the transcriptions generated by Moss.ai?`,
      content: `Yes, you can edit transcripts generated by <span className="mossai-text">Moss.ai</span> in the transcript section of the mobile application.`,
    },
    {
      title: `What devices and platforms are supported by Moss.ai?`,
      content: `<span className="mossai-text">Moss.ai</span> is supported on mobile devices and available on both Apple App Store and Android Play Store.`,
    },
    {
      title: `How can Moss.ai be useful for business or professional purposes?`,
      content: `One can use <span className="mossai-text">Moss.ai</span> in several business applications by integrating available APIs, and professionally one can use the mobile application to record audio.`,
    },
    {
      title: `Can I export transcriptions from Moss.ai to other file formats?`,
      content: `To export transcripts, we’re working on an update to avail the feature for professional users.`,
    },
    {
      title: `Are there any limitations on the length of audio that Moss.ai can transcribe?`,
      content: `No, there are no limitations on the length of audio, but we would recommend recording short, crisp and quality audio for a good quality transcription.
      `,
    },
    {
      title: `What is a lexicon?`,
      content: `A lexicon is a specialized dictionary that contains a list of words and their corresponding pronunciations.In the medical field, for example, a lexicon can be particularly useful for ensuring that technical terms and medical jargon are transcribed accurately.`,
    },
  ],
};

const styles = {
  bgColor: "rgba(0,0,0,0)",
};

const Faqs = ({ location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);
  return (
    <div className="container">
      <div className="row content m-t-100 p-t-20 p-b-20">
        <div className="col-12">
          <Faq data={data} styles={styles} config={{}} />
        </div>
      </div>
    </div>
  );
};
export default Faqs;
