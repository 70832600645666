import React from "react";
import { Card } from "react-bootstrap";
import { dateTimeFormatter, cleanUp, moneyFormatter } from "../../utils/common";

const TransactionHistory = ({ transactionHistory }) => (
  <div className="m-t-50">
    <h2 className="m-b-20">
      My <span className="mossai-text">Payment(s)</span>
    </h2>
    {transactionHistory.map((transaction) => (
      <Card className="profile-card" key={cleanUp(transaction._id)}>
        <Card.Header
        // className={isActive ? "text-white bg-success" : "text-white bg-danger"}
        >
          <Card.Title>
            <h3 className="m-b-10">
              {transaction.planType === "VALIDITY" ? (
                <span className="mossai-text">
                  {cleanUp(transaction.planName)}
                </span>
              ) : (
                cleanUp(transaction.planName)
              )}
              <small
                className={`rounded text-white m-l-20 p-t-5 p-b-5 p-l-10 m-r-10 p-r-10 pull-right ${
                  transaction.status === "success" ? "bg-success" : "bg-danger"
                }`}
              >
                {cleanUp(transaction.status)}
              </small>
            </h3>
          </Card.Title>
        </Card.Header>
        <Card.Body className="row justify-content-between">
          <Card.Text className="col-auto m-t-10">
            <i
              className={`bx ${
                transaction.planType === "VALIDITY"
                  ? "bx-purchase-tag-alt"
                  : "bx-purchase-tag"
              }`}
            />
            Type: {cleanUp(transaction.planType)}
          </Card.Text>
          <Card.Text className="col-auto m-t-10">
            <i className="bx bx-money" />
            Amount: {moneyFormatter(transaction.amount)}
          </Card.Text>
          <Card.Text className="col-auto m-t-10">
            <i className="bx bx-money-withdraw" />
            Partner Id: {transaction.paymentParterId}
          </Card.Text>
          <Card.Text className="col-auto m-t-10">
            <i className="bx bx-money-withdraw" />
            MossAI Id: {transaction.id}
          </Card.Text>
          <Card.Text className="col-auto m-t-10">
            <i className="bx bx-calendar-edit" />
            Time: {dateTimeFormatter(transaction.transDate)}
          </Card.Text>
        </Card.Body>
      </Card>
    ))}
  </div>
);

export default TransactionHistory;
