/*
 *
 * App constants
 *
 */

export const INPUT_CHANGE = "app/App/INPUT_CHANGE";
export const SUCCESS = "app/App/SUCCESS";
export const FAILURE = "app/App/FAILURE";
export const FAILED_VALIDATION = "app/App/FAILED_VALIDATION";

export const CHECK_LOGIN_STATUS = "app/App/CHECK_LOGIN_STATUS";
export const LOGOUT = "app/App/LOGOUT";

export const LOGIN = "app/App/LOGIN";
export const REGISTER = "app/App/REGISTER";
export const REQUEST_EMAIL_VERIFY = "app/App/REQUEST_EMAIL_VERIFY";
export const VERIFY = "app/App/VERIFY";

export const FORGOT_PASSWORD = "app/App/FORGOT_PASSWORD";
export const RESET_PASSWORD = "app/App/RESET_PASSWORD";
export const CHANGE_PASSWORD = "app/App/CHANGE_PASSWORD";

export const GET_PLAN = "app/App/GET_PLAN";
export const ADD_TO_CART = "app/App/ADD_TO_CART";
export const CHECKOUT = "app/App/CHECKOUT";
export const CANCEL_TRANS = "app/App/CANCEL_TRANS";
export const PAYMENT_RESPONSE = "app/App/PAYMENT_RESPONSE";

export const GET_PROFILE = "app/App/GET_PROFILE";
export const UPDATE_PROFILE = "app/App/UPDATE_PROFILE";
export const DELETE_PROFILE = "app/App/DELETE_PROFILE";

export const GET_TRANSACTION = "app/App/GET_TRANSACTION";
export const GET_SUBSCRIPTION = "app/App/GET_SUBSCRIPTION";
export const GET_USAGE = "app/App/GET_USAGE";
export const GET_MY_MEETINGS = "app/App/GET_MY_MEETINGS";
export const GET_MY_MEETING = "app/App/GET_MY_MEETING";
export const GET_SHARED_MEETINGS = "app/App/GET_SHARED_MEETINGS";
export const RESET_FLOW = "app/App/RESET_FLOW";
export const RESET_SINGLE_MEETING = "app/App/RESET_SINGLE_MEETING";
export const UPDATE_MEETING = "app/App/UPDATE_MEETING";
export const UPDATE_SPEAKER = "app/App/UPDATE_SPEAKER";

export const UPLOAD_MEETING = "app/App/UPLOAD_MEETING";

export const DOWNLOAD_FILE = "app/App/DOWNLOAD_FILE";
