export const initialState = {
  isLoading: false,
  isPageReady: false,
  formsValue: {
    login: {
      email: "",
      password: "",
    },
    register: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    updateProfile: {
      firstName: "",
      lastName: "",
    },
    requestEmailVerify: {
      email: "",
    },
    verify: {
      email: "",
      otp: "",
    },
    forgotPassword: {
      email: "",
    },
    resetPassword: {
      email: "",
      otp: "",
      password: "",
      confirmPassword: "",
    },
    changePassword: {
      email: "",
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
  },
  formsValid: {
    login: {
      email: false,
      password: false,
    },
    register: {
      firstName: false,
      lastName: false,
      email: false,
      password: false,
      confirmPassword: false,
    },
    updateProfile: {
      firstName: false,
      lastName: false,
    },
    requestEmailVerify: {
      email: false,
    },
    verify: {
      email: false,
      otp: false,
    },
    forgotPassword: {
      email: false,
    },
    resetPassword: {
      email: false,
      otp: false,
      password: false,
      confirmPassword: false,
    },
    changePassword: {
      email: false,
      oldPassword: false,
      password: false,
      confirmPassword: false,
    },
  },
  userToken: "",
  formComplete: {
    REGISTER: false,
    UPDATE_PROFILE: false,
    REQUEST_EMAIL_VERIFY: false,
    VERIFY: false,
    FORGOT_PASSWORD: false,
    RESET_PASSWORD: false,
    CHANGE_PASSWORD: false,
  },
  planList: [
    {
      id: 0,
      name: "Free",
      monthlyPrice: 0,
      quarterlyPrice: 0,
      yearlyPrice: 0,
      totalMinutes: 300,
      features: ["300 minutes of transcription every month"],
      missingFeatures: [
        "Create unlimited sessions",
        "Access all available languages",
        "Speaker diarization",
        "Bulk upload audio files for transcription",
      ],
      isUnlimited: false,
      isFree: true,
      isEnterprice: false,
      isFeatured: false,
      fadeDirection: "fade-down",
      planType: "VALIDITY",
    },
  ],
  checkoutJourney: {
    inProgress: false,
    name: "",
    price: 0,
    totalMinutes: 0,
    isUnlimited: false,
    planType: "VALIDITY",
    duration: "MONTHLY",
    ourTransId: "",
    paymentParterId: "",
    paymentStatus: "",
    subsId: "",
    planId: "",
  },
  userDetails: {
    email: "",
    firstName: "",
    lastName: "",
    id: "",
  },
  transactionHistory: [
    {
      _id: "test",
      planName: "test",
      planType: "",
      amount: 0,
      paymentParterId: "",
      status: "",
      transDate: "",
    },
  ],
  currentSubscription: [
    {
      id: "",
      name: "",
      price: 0,
      duration: "",
      totalMinutes: "",
      isUnlimited: false,
      startDate: "",
    },
  ],
  currentUsage: 0,
  myMeeting: {
    _id: "",
    userid: {
      email: "",
      firstName: "",
      lastName: "",
      id: "",
    },
    title: "",
    duration: 0,
    audioFile: "",
    language: "",
    transcript: [
      {
        seq: 1,
        success: "1",
        start_time: 0,
        end_time: 0,
        transcript: " ",
      },
    ],
    transcriptStatus: "",
    createdAt: "2023-07-26T06:54:11.026Z",
    id: "",
  },
  myMeetings: [
    {
      _id: "",
      userid: {
        email: "",
        firstName: "",
        lastName: "",
        id: "",
      },
      title: "",
      duration: 0,
      audioFile: "",
      language: "",
      transcript: [
        {
          seq: 1,
          success: "1",
          start_time: 0,
          end_time: 0,
          transcript: " ",
        },
      ],
      transcriptStatus: "",
      createdAt: "2023-07-26T06:54:11.026Z",
      id: "",
    },
  ],
  sharedMeetings: [
    {
      _id: "",
      userid: {
        email: "",
        firstName: "",
        lastName: "",
        id: "",
      },
      title: "",
      duration: 0,
      audioFile: "",
      language: "",
      transcript: [
        {
          seq: 1,
          success: "1",
          start_time: 0,
          end_time: 0,
          transcript: " ",
        },
      ],
      transcriptStatus: "",
      createdAt: "2023-07-26T06:54:11.026Z",
      id: "",
    },
  ],
};
