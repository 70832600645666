import React, { useState } from "react";
import { Link } from "react-router-dom";

import DeletePopup from "../DeletePopup";
import { Button } from "react-bootstrap";

const UserProfile = ({ userDetails, deleteAccount }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <section className="user-profile">
      <div className="profile-header-background">
        <img src="assets/img/bg-01.jpg" alt="download moss.ai background" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center col-md-auto profile-info-left">
            <img
              src={
                userDetails.profilePic &&
                userDetails.profilePic.indexOf("undefined") === -1
                  ? userDetails.profilePic
                  : "https://www.w3schools.com/w3images/avatar2.png"
              }
              alt="User Avatar"
              className="avatar img-circle"
            />
          </div>
          <div className="col">
            <div className="row align-items-end justify-content-between">
              <div className="col-12 col-md-auto profile-info">
                <h1>
                  {userDetails.firstName} {userDetails.lastName}
                </h1>
                {userDetails.email}
              </div>
              <div className="col-12 col-md-auto profile-links">
                <Link to="/change-password">Change Password</Link>|
                <Link to="/update-profile">Update Profile</Link>|
                <Button variant="link" onClick={() => setIsOpen(true)}>
                  Delete Account
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeletePopup
        isOpen={isOpen}
        onSubmit={deleteAccount}
        closeModal={() => setIsOpen(false)}
      />
    </section>
  );
};

export default UserProfile;
