import React, { useEffect, useState } from "react";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import { makeSelectAppMyMeeting } from "../../containers/App/selectors";
import { getMyMeeting, updateMeeting } from "../../containers/App/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { toast } from "react-toastify";

const EditClinicalNotes = ({
  notes,
  onSubmit,
  onClose,
  dUpdateMeeting,
  singleMeeting,
}) => {
  const [updatedNotes, setUpdatedNotes] = useState({});
  const sections = notes.split(
    /(?=Patient's History|Patient's Allergies|Differential Diagnosis)/
  );
  console.log(singleMeeting);
  const PATIENTS_CHIEF_COMPLAINS = "Patient's Chief Complaints";
  const PATIENTS_HISTORY = "Patient's History";
  const PATIENTS_ALLERGIES = "Patient's Allergies";
  const DIFFERENTIAL_DIAGNOSIS = "Differential Diagnosis";
  const handleNotesChange = (key, e) => {
    setUpdatedNotes({ ...updatedNotes, [key]: e.target.value });
  };
  useEffect(() => {
    const newUpdatedNotes = {};

    sections.forEach((sec) => {
      if (sec.includes(PATIENTS_CHIEF_COMPLAINS)) {
        const parts = sec.split(PATIENTS_CHIEF_COMPLAINS);
        newUpdatedNotes[PATIENTS_CHIEF_COMPLAINS] = parts[1]?.trim();
      }
      if (sec.includes(PATIENTS_HISTORY)) {
        const parts = sec.split(PATIENTS_HISTORY);
        newUpdatedNotes[PATIENTS_HISTORY] = parts[1]?.trim();
      }
      if (sec.includes(PATIENTS_ALLERGIES)) {
        const parts = sec.split(PATIENTS_ALLERGIES);
        newUpdatedNotes[PATIENTS_ALLERGIES] = parts[1]?.trim();
      }
      if (sec.includes(DIFFERENTIAL_DIAGNOSIS)) {
        const parts = sec.split(DIFFERENTIAL_DIAGNOSIS);
        newUpdatedNotes[DIFFERENTIAL_DIAGNOSIS] = parts[1]?.trim();
      }
    });

    setUpdatedNotes(newUpdatedNotes);
  }, []);
  const handleOnSubmit = async (e) => {
    const toString = Object.entries(updatedNotes)
      .map(([key, value]) => `${key}\n${value}`)
      .join("\n\n");
    const payload = { summary: toString };
    await dUpdateMeeting(singleMeeting._id, payload);
    toast.success("Clinical notes edited successfully");
    onClose();
  };
  const formatSections = (sec, heading) => {
    const parts = sec?.split(heading);
    return (
      <Card.Text className="mb-3">
        {parts[0].trim()}
        {heading}:
        <InputGroup size="sm">
          <Form.Control
            aria-label="Small"
            onChange={(e) => handleNotesChange(heading, e)}
            aria-describedby="inputGroup-sizing-sm"
            defaultValue={parts[1].trim()}
          />
        </InputGroup>
      </Card.Text>
    );
  };
  const separateNotes = sections.map((section) => {
    if (section.includes(PATIENTS_CHIEF_COMPLAINS)) {
      return formatSections(section, PATIENTS_CHIEF_COMPLAINS);
    }
    if (section.includes(PATIENTS_HISTORY)) {
      return formatSections(section, PATIENTS_HISTORY);
    }
    if (section.includes(PATIENTS_ALLERGIES)) {
      return formatSections(section, PATIENTS_ALLERGIES);
    }
    if (section.includes(DIFFERENTIAL_DIAGNOSIS)) {
      return formatSections(section, DIFFERENTIAL_DIAGNOSIS);
    }
    return <p className="text-black">{section.trim() + "\n"}</p>;
  });
  return (
    <>
      <Card
        className="profile-card"
        style={{ backgroundColor: "#fff", boxShadow: "none" }}
      >
        {separateNotes.map((sections, index) => (
          <Card.Text key={index}>{sections}</Card.Text>
        ))}
        <div className="container-login100-form-btn">
          <Button
            style={{ backgroundColor: "#6750A4" }}
            className="rounded-pill p-l-30 p-r-30 p-t-15 p-b-15"
            onClick={(e) => {
              handleOnSubmit(e);
            }}
            pill
          >
            Submit
          </Button>
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  singleMeeting: makeSelectAppMyMeeting(),
  // myMeetings: makeSelectAppMyMeetings(),
  // totalPages: makeSelectAppMyMeetingsTotalPages(),
});
function mapDispatchToProps(dispatch) {
  return {
    dGetMyMeeting: (id) => dispatch(getMyMeeting(id)),
    dUpdateMeeting: (id, payload) => dispatch(updateMeeting(id, payload)),
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(EditClinicalNotes);
