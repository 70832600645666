import React from "react";
        const Features = () => (
        <section id="features" className="features">
    <div className="container">
        <div className="section-title" data-aos="fade-up">
            <h2>Features</h2>
            <h3>Structured medical/clinical notes draft</h3>
            <p>
                Elevate your documentation process with Moss AI. Our application generates structured medical and clinical notes drafts, ensuring accuracy and efficiency in your practice.
            </p>
        </div>
        <div className="section-title" data-aos="fade-up">
            <h3>Supporting regional languages*</h3>
            <p className="row language-tiles">
                <strong className="col">
                    <span>
                        A<sub>a</sub>
                    </span>
                    English
                </strong>
                <strong className="col">
                    <span>
                        अ<sub>आ</sub>
                    </span>
                    Hindi
                </strong>
                <strong className="col">
                    <span>
                        ळा
                        <sub>ळा</sub>
                    </span>
                    Marathi
                </strong>
                <strong className="col">
                    <span>
                        ಆ<sub>ಆ</sub>
                    </span>
                    Kannada
                </strong>
                <strong className="col">
                    <span>
                        અ<sub>અ</sub>
                    </span>
                    Gujarati
                </strong>

                <strong className="col">
                    <span>
                        এ<sub>এ</sub>
                    </span>
                    Bengali
                </strong>
                <strong className="col">
                    <span>
                        எ<sub>எ</sub>
                    </span>
                    Tamil
                </strong>
                <strong className="col">
                    <span>
                        ఆ<sub>ఆ</sub>
                    </span>
                    Telugu
                </strong>                

            </p>
            <small>*Transcript quality depends on audio quality</small>
        </div>
        <div className="section-title" data-aos="fade-up">
            <h3>Streamline administrative tasks</h3>
            <p>
                Follow-through to every medical conversation. Time consuming paperwork converted to more time with patients.
            </p>
        </div>
        <div className="section-title" data-aos="fade-up">
            <h3>Ground reality insights</h3>
            <p>
                Gain invaluable insights with <span className="mossai-text">Moss.ai</span>. Whether it's through audio recordings or transcribed text, our application brings you closer to the ground reality of patient encounters, filling the gaps left by traditional practices and enhancing the quality of care provided.
            </p>
        </div>        
    </div>
</section>
        );
export default Features;
