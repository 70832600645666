import React from "react";
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Button,
} from "react-bootstrap";

const DeletePopup = ({ isOpen, onSubmit, closeModal }) => {
  return (
    <Modal size="md" show={isOpen}>
      <ModalHeader className="bg-danger">
        <ModalTitle className="text-white">Are You Sure?</ModalTitle>
      </ModalHeader>
      <ModalHeader>
        <strong className="font-italic text-warning">
          Unexpected bad things will happen if you don’t read this!
        </strong>
      </ModalHeader>

      <ModalBody>
        <ul style={{ listStyle: "circle", paddingLeft: "20px" }}>
          <li style={{ listStyle: "inherit" }}>
            This will permanently delete your account, its recordings and any
            insights associated with it.
          </li>
          <li style={{ listStyle: "inherit" }}>
            This will remove all collaborator associations.
          </li>
          <li style={{ listStyle: "inherit" }}>
            This will not change your billing plan. If you want to downgrade,
            you can do contact us.
          </li>
        </ul>
      </ModalBody>

      <ModalFooter className="pricing">
        <Button
          className="get-started-btn-outline m-l-10"
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button
          className="get-started-btn"
          onClick={() => {
            onSubmit();
            closeModal();
            // history.push("/carts");
          }}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeletePopup;
