import React from "react";
        const WhyMossAI = () => (
        <section id="why-moss-ai" className="details">
    <div className="container features">
        <div className="section-title">
            <h2>
                Why <span className="mossai-text">Moss.ai</span>?
            </h2>
        </div>
        <div className="row content">
            <div
                className="content d-flex flex-column justify-content-center p-0"
                data-aos="fade-up"
                >
                <div className="row">
                    <div  className="col-md-4 icon-box aos-init aos-animate" data-aos="fade-up">
                        <i class='bx bx-time-five'></i>                        
                        <h4>
                            Saves upto <b>3 hours</b> per day per clinician
                        </h4>
                    </div>
                    <div  className="col-md-4 icon-box aos-init aos-animate" data-aos="fade-up">
                        <i class='bx bx-trending-up'></i>                     
                        <h4>
                            Seen upto <b>20%</b> increases in productivity
                        </h4>
                    </div>
                    <div  className="col-md-4 icon-box aos-init aos-animate" data-aos="fade-up">
                        <i class='bx bx-body'></i>                      
                        <h4>
                            Make it <b>easy for patients</b> to be on top of their healthcare
                        </h4>
                    </div>
                    <div  className="col-md-4 icon-box aos-init aos-animate" data-aos="fade-up">
                        <i class='bx bx-trending-down' ></i>                       
                        <h4>
                            <b>78%</b> reduction in documentation time
                        </h4>
                    </div>
                    <div  className="col-md-4 icon-box aos-init aos-animate" data-aos="fade-up">
                        <i className="bx bx-receipt"></i>                        
                        <h4>
                            <b>Patients</b> over paperwork
                        </h4>
                    </div>
                    <div  className="col-md-4 icon-box aos-init aos-animate" data-aos="fade-up">
                        <i class='bx bx-conversation' ></i>                        
                        <h4>
                            Capture <b>value from the beginning</b> of every conversation
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
        );
export default WhyMossAI;
