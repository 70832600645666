export const formFields = [
  {
    readOnly: false,
    label: "Email",
    name: "email",
    type: "email",
    placeholder: "Enter Registered Email",
    validations: [
      { name: "isRequired", message: " is Mandatory" },
      { name: "isEmail", message: " is Valid Email" },
    ],

    fieldName: "email",
    validationRules: {
      isRequired: true,
      isEmail: true,
    },
  },
];
