import React from "react";

const OurInvestors = ({ investors }) => (
  <section id="ourInvestors">
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>Champions of Progress</h2>
        <h3>
          Honoring Our Dedicated{" "}
          <span className="mossai-text">Stakeholders and Mentors</span>
        </h3>
        <p>
          Exceptional individuals who have illuminated our journey and fortified
          our endeavors over the past four years. These esteemed stakeholders
          and mentors have consistently demonstrated unwavering dedication,
          acting as both guiding lights and pillars of support, propelling us
          towards success and inspiring us to achieve new heights through their
          invaluable contributions and steadfast commitment.
        </p>
      </div>
      <div className="our-investors row">
        {investors.map((investor) => (
          <div className="col-12 col-md-6" key={investor.name}>
            <a href={investor.link} target="_blank" rel="noreferrer">
              <div className="testimonial-item">
                <div className="profile mt-auto">
                  <img
                    src={`assets/img/team/${investor.img}.jpeg`}
                    className="testimonial-img"
                    alt={`${investor.name} ${investor.title}`}
                  />
                </div>
                <h3>{investor.name}</h3>
                <h4>{investor.title}</h4>
                <p>{investor.bio}</p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default OurInvestors;
