import React from "react";
import { NavHashLink }
from "react-router-hash-link";
const PlanList = ({
        plan, addToCart, isLogin, isPlanActive, userDetails, price }) => (
                <div
    className={
                plan.id == "1"
                ? "d-none"
                : `col-12 col-md-4 box d-flex justify-content-between flex-column ${plan.isFeatured &&
                "featured"}`
    }
    data-aos={plan.fadeDirection}
    key={plan.name}
    >
    <div>
        <h3>{plan.name}</h3>
    </div>
    <div>
        {(plan.isFree) && (
                <h4 className="p-t-30">
            Free            
        </h4>
                )}
        
        {((!isLogin && plan.price) || (userDetails && userDetails?.country == "IN" && plan.price)) && (
        <h4 className="p-t-30">
            &#8377;{plan.price}
            <span>Cost</span>
        </h4>
        )}
        
        {(isLogin && (userDetails?.country != "IN" && plan.price_EUR)) && (
        <h4>
            &#8364;{plan.price_EUR}
            <span>Cost</span>
        </h4>
        )}
        
        {plan.enterpricePrice && (
                <h4 className="p-t-30">
                    {plan.enterpricePrice}
                    <span>Customize your plan on the basis of your requirements</span>
                </h4>
                )
}
        
        {(price == "monthly" && ((!isLogin && plan.monthlyPrice) || (userDetails && userDetails?.country == "IN" && plan.monthlyPrice))) && (
        <h4>
            &#8377;{plan.monthlyPrice}
            <span>Monthly</span>
        </h4>
        )}
        
        {(price == "monthly" && (isLogin && (userDetails?.country != "IN" && plan.monthlyPrice_EUR))) && (
        <h4>
            &#8364;{plan.monthlyPrice_EUR}
            <span>Monthly</span>
        </h4>
        )}
        
        {(price == "quaterly" && ((!isLogin && plan.quarterlyPrice) || (userDetails && userDetails?.country == "IN" && plan.quarterlyPrice))) && (
        <h4>
            &#8377;{plan.quarterlyPrice}
            <span>Quarterly</span>
        </h4>
        )}
        
        {(price == "quaterly" && (isLogin && (userDetails?.country != "IN" && plan.quarterlyPrice_EUR))) && (
        <h4>
            &#8364;{plan.quarterlyPrice_EUR}
            <span>Quarterly</span>
        </h4>
        )}
        
        {(price == "annual" && ((!isLogin && plan.yearlyPrice) || (userDetails && userDetails?.country == "IN" && plan.yearlyPrice))) && (
        <h4>
            &#8377;{plan.yearlyPrice}
            <span>Annually</span>
        </h4>
        )}
        
        {(price == "annual" && (isLogin && (userDetails?.country != "IN" && plan.yearlyPrice_EUR))) && (
        <h4>
            &#8364;{plan.yearlyPrice_EUR}
            <span>Annually</span>
        </h4>
        )}
        
    </div>
    <div>
        <ul>
            
        {plan.features.map((item) => (
                <li key={item}>
                <i className="bx bx-check"></i> {item}
            </li>
                ))}
            {plan.missingFeatures.map((item) => (
                <li key={item} className="na">
                <i className="bx bx-x"></i> <span>{item}</span>
            </li>
                ))}
        </ul>
        {isLogin ? (
                <>     




        
        {plan.isEnterprice ? (
                <NavHashLink className="get-started-btn" to="/home#contact">
                    <b>Talk to our Experts</b>
                </NavHashLink>
                ) : (
                <button
                    className="get-started-btn"
                    onClick={() => addToCart(plan)}
                    disabled={isPlanActive}
                    >
                    <b>{isPlanActive ? "Have Active Plan" : "Add to Cart"}</b>
                </button>
                )
        }
        




        </>
        ) : (
        <>
        <NavHashLink className="get-started-btn" to="/login">
            <b>Login</b>
        </NavHashLink>
        </>
        )}
    </div>
</div>
        );
        export default PlanList;
